import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import axios from "axios";

function update_account(data){

  var actionUrl = '/pages/update_account';
  const isLoggedIn = JSON.parse(localStorage.getItem('user'));

  return axios ({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + actionUrl,
      data: {
        data,
        user_id: isLoggedIn.id
      },
      headers: { 'Content-Type': 'application/json;charset=UTF-8', "Access-Control-Allow-Origin": "*", "Accept": "application/json" }
  });
}

function update_company_account(data){

  var actionUrl = '/pages/update_company_account';
  const isLoggedIn = JSON.parse(localStorage.getItem('user'));

  return axios ({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + actionUrl,
      data: {
        data,
        user_id: isLoggedIn.id,
        company_id: isLoggedIn.company_id
      },
      headers: { 'Content-Type': 'application/json;charset=UTF-8', "Access-Control-Allow-Origin": "*", "Accept": "application/json" }
  });
}

function* updateUserAccount(payload) {
  try {
    const resp = yield call(update_account, payload.payload.data);
    if(resp.data.json.error == false) {
      if(resp.data.json.user) {
        // Do something
        localStorage.setItem("user", JSON.stringify(resp.data.json.user));
      }
    }
    yield put(actions.postToApiSuccessUser(resp.data.json));
  } catch (error) {
    yield put(actions.postToApiError(error));
  }
}

function* updateCompanyAccount(payload) {
  try {
    const resp = yield call(update_company_account, payload.payload.data);
    if(resp.data.json.error == false) {
      if(resp.data.json.user) {
        // Do something
        localStorage.setItem("user", JSON.stringify(resp.data.json.user));
      }
    }
    yield put(actions.postToApiSuccessCompany(resp.data.json));
  } catch (error) {
    yield put(actions.postToApiError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPDATE_USER_ACCOUNT, updateUserAccount),
    takeEvery(actions.UPDATE_COMPANY_ACCOUNT, updateCompanyAccount),
  ]);
}