import React, { Component, useState, useEffect, useRef }  from 'react';
import { useForm } from "react-hook-form";
import DateTimePicker from 'react-datetime-picker';
import Header from '../components/Header.js';
import Banner from '../components/Banner.js';
import Contact from '../components/Contact.js';
import Footer from '../components/Footer.js';
import Testimonials from '../components/Testimonials.js';
import Select from 'react-select';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import featuredImage from '../img/about.png';
import backArrow from '../img/back.svg';
import axios from "axios";
import moment from "moment";
import { useHistory ,useLocation } from 'react-router-dom';

import GooglePlacesAutocomplete from "react-google-places-autocomplete";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Order() {

    const location = useLocation();

    const [step, setStep] = useState(1);
    const [loader, setLoader] = useState(false);
    const [effectLoad, setEffectLoad] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [addressLabel, setAdressLabel] = useState('Address');
    const [address, setAdress] = useState({});
    const d = new Date();
    const currentDate = moment(d);
    const currentDateNew = currentDate.format("MM/DD/YYYY HH:mm:ss ");

    const [formData, setFormData] = useState({type: '', location_details: '', name: '', full_name: '', email: '', phone_number: '', services: [], address: '', date: currentDateNew, value_date: new Date()});
    const [isSelected, setSelected] = useState(false);

    const [value, onChange] = useState(new Date());

    const handleData = (type, infoData) => {
        setEffectLoad(false);
        if(step === 1) {
            setEffectLoad(true);
            const newObj = { ...formData, type: infoData };
            setFormData(newObj);
            setTimeout(() => {setStep(2)}, 2000)
        } else if(step === 2) {
            let servicesArray = formData.services;
            if(servicesArray.indexOf(infoData) > -1) {
                const index = servicesArray.indexOf(infoData);
                if (index > -1) { // only splice array when item is found
                    servicesArray.splice(index, 1); // 2nd parameter means remove one item only
                }
            } else {
                servicesArray.push(infoData);
            }
            const newObj = { ...formData, services: servicesArray };
            setFormData(newObj);
        }
        console.log(formData);
    };

    const forceBack = (goto) => {
        setEffectLoad(false);
        setErrorMessage('');
        if(goto == 1) {
            setStep(goto);
        } else if(goto == 2) {
            setStep(goto);
        } else if(goto == 3) {
            setStep(goto);
        } else if(goto == 4) {
            setStep(goto);
        }
    };

    const forceNext = (goto) => {
        setEffectLoad(false);
        setErrorMessage('');
        
        if(step == 1 && goto == 2) {
            if(!formData.name) {
                setErrorMessage('Please specify your business name');
            } else if(!formData.email) {
                setErrorMessage('Please specify your email address');
            } else {
                setEffectLoad(true);
                setStep(goto);
            }
        } else if(step == 2 && goto == 3) {
            if(formData.services.length > 0) {
                setEffectLoad(true);
                setStep(goto);
            } else {
                setErrorMessage('Please choose at least one invoice');
            }
        }
    };

    const handleStyleProgress = (step) => {
        if (step === 1) {
            return {
                width: '15%'
            }
        } else if (step === 2) {
            return {
                width: '30%'
            }
        } else if (step === 3) {
            return {
                width: '55%'
            }
        } else if (step === 4) {
            return {
                width: '75%'
            }
        } else if (step === 5) {
            return {
                width: '85%'
            }
        } else {
            return {
                width: '100%'
            }
        }
    };

    const handleContactDetails = (val, type) => {
        if(type == 'name') {
            const newObj = { ...formData, name: val };
            setFormData(newObj);
        } else if(type == 'email') {
            const newObj = { ...formData, email: val };
            setFormData(newObj);
        }
    };

    const submitForm = () => {
   
        setErrorMessage('');
        let error = 0;
        setLoader(true);

        if(!formData.name) {
            setErrorMessage('Please specify your business name');
            error++;
        } else if(!formData.email) {
            setErrorMessage('Please specify your email address');
            error++;
        }
         // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(formData.email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
        }
        else {
            setErrorMessage('Your email address does not have a valid format');
            error++;
        }

        if(error == 0) {

            axios({
                method: "POST",
                url: process.env.REACT_APP_API_URL + '/frontend/xorix_order',
                headers: { 'Content-Type': 'application/json;charset=UTF-8', "Access-Control-Allow-Origin": "*", "Accept": "application/json" },
                data: {
                 data: formData
               }
              })
                .then(result => {
                    if(result.data.json.error == false) {
                        setLoader(false);
                        setStep(6);
                    } else {
                       setErrorMessage(result.data.json.message);
                       setLoader(false);
                    }
                })
                .catch(error => setErrorMessage( error.message ));
        } else {
            setLoader(false);
        }

    };

  return (
   
   <div className="App" id="web-front">

      <Header isWhite={false} />

        <div className="base-div-wrapper d-flex flex-column flex-1">
            <div className="egrJmc"></div>

            <div className="base-div-2 d-flex flex-column flex-1 h100">

            {loader && 
                <div className="loader-inner">
                    <div className="loader"></div>
                </div>
            }
                
                <div className="base-div d-flex flex-column flex-1 bg-white">

               <div className="mt-4 d-flex align-items-center">

                {step > 1 && step != 6 && !loader && 
                    <a onClick={() => forceBack(step - 1)} className="d-flex flex-column align-items-center justify-content-center backarrow">
                        <img src={backArrow} />
                    </a>
                }

                        <div className="progress mx-2">
                            <div className="progress-bar" style={handleStyleProgress(step)}></div>
                        </div>
               </div>
                    <div className="container d-flex flex-column flex-1">
                        <div className="d-flex flex-column justify-content-center mt-4 mb-4">

                        {step == 1 && 
                        <>

                            <h3 className="text-center m-0 ">Your details</h3>
                            
                            <div class="row no-gutters mt-4 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="name" placeholder="Name" id="name" value={formData.name} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'name')} />
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-3">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="email" placeholder="Email" id="email" value={formData.email} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'email')} />
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => forceNext(2)} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ">Next</button>

                        </>
                        }
                        
                        {step == 2 && 
                        <>

                            <h3 className="text-center m-0 ">Account</h3>

                            <div class="row no-gutters mt-4 mb-3">
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'photography')} type="primary" className={formData.services.indexOf('photography') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices normal_cursor' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA normal_cursor'}>
                                        <span className="alignb">Invoice C20212<br />Due date: xxx<br />View Invoice</span>
                                    </span>
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => forceNext(3)} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ">Next</button>

                            </>
                        }

                        {step == 3 && 
                        <>
                            <h3 className="text-center m-0 ">Credit Card Details</h3>
                            <div class="row no-gutters mt-4 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="business_name" placeholder="Credit Card Number" id="name" value={formData.name} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'name')} />
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="full_name" placeholder="Full Name" id="full_name" value={formData.full_name} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'full_name')} />
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => submitForm()} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ orderNow" disabled={loader}>Submit</button>
                        </>
                        }

                        {step == 4 && 
                        <>
                            <h3 className="text-center m-0">Thank you!</h3>
                            <p className="mt-4">Thank you for placing an order with us. Our team is dedicated to ensuring that your order is processed quickly and efficiently, and that you are completely satisfied with your order.<br />
                            <br />If you have any questions or concerns, please do not hesitate to contact us. Our customer service team is always here to help.</p>
                            </>
                        }

                        </div>
                    </div>
                </div>
            </div>
        </div>

   </div>
  )
}