import React, { Component }  from 'react';
import Header from './components/Header.js';
import Footer from './components/Footer.js';
import { useParallax } from "react-scroll-parallax";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

export default function Home() {

   const { t, i18n } = useTranslation();

  return (
   
<div className="App">
    
   <Header />



<Footer />


    </div>
  )
}
