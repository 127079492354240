import React, { Component, useState, useEffect }  from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import axios from "axios";
import logo from '../img/logo.png';

function Footer() {

    return (
      <div>
         <div data-testid="footer" className="styles__StyledFooter-sc-1vgxo9-0 kFpqvT" id="footer">
      <div style={{maxWidth:'89rem', margin: '0px auto'}}>
         <div className="row text-center text-xs-center text-sm-left text-md-left">
            <div className="col-md-2 text-right fLogoCenter">
               <img src={logo} className="logo" />
            </div>
            <div className="col-xs-12 col-sm-4 offset-md-1 col-md-8">
               <h5>Solutions</h5>
               <div className="row no-gutters">
                  <div className="col-sm-3 col-md-3 col-6">
                     <h6>Photography</h6>
                     <ul className="list-unstyled quick-links">
                        <li><a href={`${process.env.REACT_APP_URL}/real-estate/photography`}>Real Estate</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/commercial/photography`}>Commercial</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/aerial/photography`}>Aerial</a></li>
                     </ul>
                     <h6>
                        <a className="unset-color" href={`${process.env.REACT_APP_URL}/real-estate/drones`}>Drones</a>
                     </h6>
                     <h6><a href={`${process.env.REACT_APP_URL}/real-estate/virtual-furniture`} className="unset-color">Virtual Furniture</a></h6>
                  </div>
                  <div className="col-sm-3 col-md-3 col-6">
                     <h6>Floorplans</h6>
                     <ul className="list-unstyled quick-links">
                        <li><a href={`${process.env.REACT_APP_URL}/floor-plans/black-and-white`}>Black & White</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/floor-plans/colour`}>Colour</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/floor-plans/site-plans`}>Site Plans</a></li>
                     </ul> 
                     <h6><a href={`${process.env.REACT_APP_URL}/real-estate/virtual-tours`} className="unset-color">Virtual Tours</a></h6>
                     <h6>
                        <a className="unset-color" href={`${process.env.REACT_APP_URL}/real-estate/graphic-design`}>Graphic Design</a>
                     </h6>
                  </div>
                  <div className="col-sm-3 col-md-3 col-6">
                     <h6>Video</h6>
                     <ul className="list-unstyled quick-links">
                        <li><a href={`${process.env.REACT_APP_URL}/real-estate/property-video`}>Property</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/real-estate/agents-video`}>Agents</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/real-estate/auctions-video`}>Auctions</a></li>
                     </ul>
                     <h6>
                        <a className="unset-color" target="_blank" href={'https://rubii.com'}>LEARN MORE...</a>
                     </h6>
                  </div>
                  <div className="col-sm-3 col-md-3 col-6">
                     <h6>About</h6>
                     <ul className="list-unstyled quick-links">
                        <li><a href={`${process.env.REACT_APP_URL}/about`}>About</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/contact`}>Contact</a></li>
                        <li><a href={`${process.env.REACT_APP_URL}/terms`}>Terms</a></li>
                     </ul>
                  </div>
               </div>
            </div>

         </div> 

      </div>

      <div class="row no-gutters text-right privacycccc">
            <div className="offset-md-2 col-md-8 col-sm-12 col-xs-12">
               <ul className="list-unstyled quick-links-add">
                  <li>Xorix is a subsidiary of OMGD Limited <a href="https://rubii.com" target="_blank">Rubii</a>.<br />
                  All Rights Reserved | Copyright © 2023 Xorix.</li>
               </ul>
            </div>
         </div>


</div>
      </div>
    )
}

export default Footer



