import React, {useState, useEffect} from 'react';
import { Link, NavLink } from 'react-router-dom';
import logo from '../img/logo.png';
import logoWhite from '../img/logo-white.png';
import { useHistory ,useLocation } from 'react-router-dom';

function Header({isWhite}) {

    const [scroll, setScroll] = useState(false);
    const [menuMobile, setMenuMobile] = useState(false);
    const location = useLocation();

    const navMobileOpen = () => {
        if(menuMobile) {
            setMenuMobile(false);
        } else {
            setMenuMobile(true);
        }
    };

    useEffect(() => {
        console.log(location.pathname)
        if(location.pathname != '/real-estate/property-video' || location.pathname != '/real-estate/agents-video' || location.pathname != '/real-estate/auctions-video') {
            let isMounted = true; // note mutable flag
            window.addEventListener("scroll", () => {
                if (isMounted) setScroll(window.scrollY > 5);
            })
            return () => { isMounted = false }; // cleanup toggles value, if unmounted
        }
      }, []);

    // 👇 add class to body element only if page is /order
    useEffect(() => {
        if(location.pathname === '/order') {
            document.body.classList.add('order-wizard');
        } else {
            document.body.classList.remove('order-wizard');
        }
    }, []);

    return (

    <div>

        <div className={scroll ? "styles__StyledStickyWrapper-wdvryq-0 eFYmXK" : "styles__StyledStickyWrapper-wdvryq-0 eFYmXK"}>
        <div data-testid="header" className="styles__StyledHeader-wdvryq-1 hpocnJ">
            <nav className="navbar navbar-expand-lg">
                <div className="container">
                <a href="https://xorix.com" className="styles__StyledLink-srcz35-0 eDvWtb styles__StyledLogoLink-wdvryq-2 jRooNT">
                    {isWhite ?  <img src={logoWhite} alt="" className="rLogo logoxmSize" /> : <img src={logo} alt="" className="rLogo logoxmSize" />}
                    {scroll ?  <img src={logo} alt="" className="rLogoExpender logoxmSize" /> : ''}
                </a>
                </div>
            </nav>
        </div>
        </div>

    </div>

    )
}

export default Header
