const actions = {
    UPDATE_USER_ACCOUNT: 'UPDATE_USER_ACCOUNT',
    UPDATE_COMPANY_ACCOUNT: 'UPDATE_COMPANY_ACCOUNT',
    POST_TO_API_SUCCESS: 'POST_TO_API_SUCCESS',
    POST_TO_API_ERROR: 'POST_TO_API_ERROR',
  
    postToApi: data => {
      return {
        type: actions.POST_TO_API,
        payload: { data },
      };
    },
    updateUserAccount: data => {
      return {
        type: actions.UPDATE_USER_ACCOUNT,
        payload: { data },
      };
    },
    updateCompanyAccount: data => {
      return {
        type: actions.UPDATE_COMPANY_ACCOUNT,
        payload: { data },
      };
    },
    postToApiSuccess: data => ({
      type: actions.POST_TO_API_SUCCESS,
      payload: { data },
    }),
    postToApiError: error => ({
      type: actions.POST_TO_API_ERROR,
      payload: { error },
    }),
  };
  export default actions;