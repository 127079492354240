import actions from './actions';

const initState = { 
  isLoading: false,
  errorMessage: false,
  accountUser: [],
  accountCompany: []
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.UPDATE_USER_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
    };
    case actions.UPDATE_COMPANY_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
    };
    case actions.POST_TO_API_SUCCESS_USER:
      return {
        ...state,
        isLoading: false,
        accountUser: payload.data,
        errorMessage: false
    };
    case actions.POST_TO_API_SUCCESS_COMPANY:
      return {
        ...state,
        isLoading: false,
        accountCompany: payload.data,
        errorMessage: false
    };
    case actions.POST_TO_API_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a problem'
    };
    default:
      return state;
  }
}