import actions from './actions';

const initState = { 
  isLoading: false,
  errorMessage: false,
  data: []
};

export default function reducer(
  state = initState,
  { type, payload }
) {
  switch (type) {
    case actions.UPDATE_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
    };
    case actions.UPDATE_COMPANY_ACCOUNT:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
    };
    case actions.POST_TO_API:
      return {
        ...state,
        isLoading: true,
        errorMessage: false
      };
    case actions.POST_TO_API_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        errorMessage: false
    };
    case actions.POST_TO_API_SUCCESS_COMPANY:
      return {
        ...state,
        isLoading: false,
        data: payload.data,
        errorMessage: false
    };
    case actions.LOGOUT_USER:
      return {
        ...initState,
    };
    case actions.RESET_INITIAL_STATE:
      return {
        ...initState,
    };
    case actions.RESET_DATA:
      return {
        ...state,
        data: []
    };
    case actions.POST_TO_API_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a problem'
    };
    default:
      return state;
  }
}