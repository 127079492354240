const actions = {
  LOGOUT_USER: 'LOGOUT_USER',
  POST_TO_API: 'POST_TO_API',
  UPDATE_ACCOUNT: 'UPDATE_ACCOUNT',
  UPDATE_COMPANY_ACCOUNT: 'UPDATE_COMPANY_ACCOUNT',
  POST_TO_API_SUCCESS: 'POST_TO_API_SUCCESS',
  POST_TO_API_SUCCESS_COMPANY: 'POST_TO_API_SUCCESS_COMPANY',
  POST_TO_API_ERROR: 'POST_TO_API_ERROR',
  RESET_INITIAL_STATE: 'RESET_INITIAL_STATE',

  postToApi: data => {
    return {
      type: actions.POST_TO_API,
      payload: { data },
    };
  },
  updateAccount: data => {
    return {
      type: actions.UPDATE_ACCOUNT,
      payload: { data },
    };
  },
  updateCompanyAccount: data => {
    return {
      type: actions.UPDATE_COMPANY_ACCOUNT,
      payload: { data },
    };
  },
  logoutUser: data => {
    return {
      type: actions.LOGOUT_USER
    };
  },
  resetInitialState: data => {
    return {
      type: actions.RESET_INITIAL_STATE
    };
  },
  postToApiSuccess: data => ({
    type: actions.POST_TO_API_SUCCESS,
    payload: { data },
  }),
  postToApiSuccessCompany: data => ({
    type: actions.POST_TO_API_SUCCESS_COMPANY,
    payload: { data },
  }),
  postToApiError: error => ({
    type: actions.POST_TO_API_ERROR,
    payload: { error },
  }),
};
export default actions;