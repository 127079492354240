import { combineReducers } from 'redux';
import App from './app/reducer';
import Auth from './auth/reducer';
import Users from './users/reducer';

export default combineReducers({
  Auth,
  Users,
  App
});
