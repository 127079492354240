import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";

//Creating object with the variables of imported translation files
const resources = {
  fr: {
    translation: translationFR,
  },
  en: {
    translation: translationEN,
  },
};

var userLanguage = window.navigator.userLanguage || window.navigator.language;
var savedLocale = 'fr';

if(localStorage.getItem("locale") == null) {
    if(userLanguage === 'fr-FR') {
      savedLocale = 'fr';
    } else {
      //savedLocale = 'en';
      savedLocale = 'fr';
    }
    localStorage.setItem("locale", savedLocale);
}

savedLocale = localStorage.getItem("locale");

//i18N Initialization
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:savedLocale, //default language
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;