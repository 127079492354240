import React, { Component, useState, useEffect, useRef }  from 'react';
import { useForm } from "react-hook-form";
import DateTimePicker from 'react-datetime-picker';
import Header from '../components/Header.js';
import Banner from '../components/Banner.js';
import Contact from '../components/Contact.js';
import Footer from '../components/Footer.js';
import Testimonials from '../components/Testimonials.js';
import Select from 'react-select';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import featuredImage from '../img/about.png';
import backArrow from '../img/back.svg';
import axios from "axios";
import moment from "moment";
import { useHistory ,useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import GooglePlacesAutocomplete from "react-google-places-autocomplete";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

export default function Order() {

    const location = useLocation();

    const [step, setStep] = useState(1);
    const [loader, setLoader] = useState(false); 
    const [effectLoad, setEffectLoad] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [addressLabel, setAdressLabel] = useState('Address');
    const [address, setAdress] = useState({});
    const d = new Date();
    const currentDate = moment(d);
    const currentDateNew = currentDate.format("MM/DD/YYYY HH:mm:ss ");

    const [formData, setFormData] = useState({type: 'business', location_details: '', name: '', full_name: '', email: '', phone_number: '', services: [], address: '', date: currentDateNew, value_date: new Date()});
    const [isSelected, setSelected] = useState(false);

    const [value, onChange] = useState(new Date());

    const handleData = (type, infoData) => {
        setEffectLoad(false);
       if(step === 2) {
            let servicesArray = formData.services;
            if(servicesArray.indexOf(infoData) > -1) {
                const index = servicesArray.indexOf(infoData);
                if (index > -1) { // only splice array when item is found
                    servicesArray.splice(index, 1); // 2nd parameter means remove one item only
                }
            } else {
                servicesArray.push(infoData);
            }
            const newObj = { ...formData, services: servicesArray };
            setFormData(newObj);
        }
        console.log(formData);
    };

    const forceBack = (goto) => {
        setEffectLoad(false);
        setErrorMessage('');
        if(goto == 1) {
            setStep(goto);
        } else if(goto == 2) {
            setStep(goto);
        } else if(goto == 3) {
            setStep(goto);
        } else if(goto == 4) {
            setStep(goto);
        }
    };

    const forceNext = (goto) => {
        setEffectLoad(false);
        setErrorMessage('');

        if(step == 1 && goto == 2) {
            setEffectLoad(true);
            setStep(goto);
        } else if(step == 2 && goto == 3) {
            if(formData.services.length > 0) {
                setEffectLoad(true);
                setStep(goto);
            } else {
                setErrorMessage('Please choose at least one service');
            }
        } else if(step == 3 && goto == 4) {
            if(formData.address != '') {
                setEffectLoad(true);
                setStep(goto);
            } else {
                setErrorMessage('Please specify the address');
            }
        }
        else if(step == 4 && goto == 5) {
            if(formData.date != '') {
                setEffectLoad(true);
                setStep(goto);
            } else {
                setErrorMessage('Please specify the date and time');
            }
        }
    };

    const handleAddress = (val) => {
        const newObj = { ...formData, address: val };
        setFormData(newObj);
    };

    const handleStyleProgress = (step) => {
        if (step === 1) {
            return {
                width: '5%'
            }
        } else if (step === 2) {
            return {
                width: '15%'
            }
        } else if (step === 3) {
            return {
                width: '55%'
            }
        } else if (step === 4) {
            return {
                width: '75%'
            }
        } else if (step === 5) {
            return {
                width: '85%'
            }
        } else {
            return {
                width: '100%'
            }
        }
    };

    const handleAddressChange = (details) => {
        setAdressLabel(details.label);
        const newObj = { ...formData, address: details.value.description };
        setFormData(newObj);
    };

    const handleDateChange = (val) => {

        var d = new Date(val);
        var newdate = moment(d);
        const myDate = newdate.format("MM/DD/YYYY HH:mm:ss ");

        const newObj = { ...formData, date: myDate };
        setFormData(newObj);
    };

    const handleContactDetails = (val, type) => {
        if(type == 'name') {
            const newObj = { ...formData, name: val };
            setFormData(newObj);
        } else if(type == 'full_name') {
            const newObj = { ...formData, full_name: val };
            setFormData(newObj);
        } else if(type == 'email') {
            const newObj = { ...formData, email: val };
            setFormData(newObj);
        } else if(type == 'phone_number') {
            const newObj = { ...formData, phone_number: val };
            setFormData(newObj);
        } else if(type == 'location_details') {
            const newObj = { ...formData, location_details: val };
            setFormData(newObj);
        }
    };

    const submitForm = () => {
   
        setErrorMessage('');
        let error = 0;
        setLoader(true);

        if(!formData.name) {
            setErrorMessage('Please specify your business name');
            error++;
        } else if(!formData.full_name) {
            setErrorMessage('Please specify your full name');
            error++;
        } else if(!formData.email) {
            setErrorMessage('Please specify your email address');
            error++;
        } else if(!formData.phone_number) {
            setErrorMessage('Please specify your phone number');
            error++;
        }
         // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if ( re.test(formData.email) ) {
            // this is a valid email address
            // call setState({email: email}) to update the email
            // or update the data in redux store.
        }
        else {
            setErrorMessage('Your email address does not have a valid format');
            error++;
        }

        if(error == 0) {

            axios({
                method: "POST",
                url: process.env.REACT_APP_API_URL + '/frontend/xorix_order',
                headers: { 'Content-Type': 'application/json;charset=UTF-8', "Access-Control-Allow-Origin": "*", "Accept": "application/json" },
                data: {
                 data: formData
               }
              })
                .then(result => {
                    if(result.data.json.error == false) {
                        setLoader(false);
                        setStep(6);
                    } else {
                       setErrorMessage(result.data.json.message);
                       setLoader(false);
                    }
                })
                .catch(error => setErrorMessage( error.message ));
        } else {
            setLoader(false);
        }

    };

  return (
   
   <div className="App" id="web-front">

    <Helmet>
        <title>Book Professional Real Estate Photography & Video Services Online</title>
        <meta name="description" content="Schedule professional real estate photography, video tours, and floor plan services online with Xorix. Elevate your listings and attract potential buyers effortlessly." />
      </Helmet>

      <Header isWhite={false} />

        <div className="base-div-wrapper d-flex flex-column flex-1">
            <div className="egrJmc"></div>

            <div className="base-div-2 d-flex flex-column flex-1 h100">

            {loader && 
                <div className="loader-inner">
                    <div className="loader"></div>
                </div>
            }
                
                <div className="base-div d-flex flex-column flex-1 bg-white">

               <div className="mt-4 d-flex align-items-center">

                        {step == 1 && !loader && 
                            <a href="https://xorix.com" className="d-flex flex-column align-items-center justify-content-center backarrow">
                                <img src={backArrow} />
                            </a>
                        }

                        {step > 1 && step != 6 && !loader && 
                            <a onClick={() => forceBack(step - 1)} className="d-flex flex-column align-items-center justify-content-center backarrow">
                                <img src={backArrow} />
                            </a>
                        }

                        <div className="progress mx-2">
                            <div className="progress-bar" style={handleStyleProgress(step)}></div>
                        </div>
               </div>
                    <div className="container d-flex flex-column flex-1">
                        <div className="d-flex flex-column justify-content-center mt-4 mb-4">

                        {step == 1 && !loader && 
                        <>

                            <h1 className="text-center m-0 main-booking-title">Are you an existing customer?</h1>

                            <div class="row no-gutters mt-4">
                                <div class="col-6">
                                    <a target="_blank" href="https://app.xorix.com/portal" className="w100 styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ text-center fyellow">
                                        <span className="alignb">Yes, sign in</span>
                                    </a>
                                </div>
                                <div class="col-6">
                                    <button className="w100 styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ text-center" onClick={() => forceNext(2)} type="primary">
                                        No, continue as guest
                                    </button>
                                    <p className="text-center">Get a quote in seconds</p>
                                </div>
                            </div>

                            </>
                        }
                        
                        {step == 2 && !loader && 
                        <>

                            <h1 className="text-center m-0 main-booking-title">Tell us what you need</h1>

                            <div class="row no-gutters mt-4 mb-3">
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'photography')} type="primary" className={formData.services.indexOf('photography') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Photography</span>
                                    </span>
                                </div>
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'floor_plans')} type="primary" className={formData.services.indexOf('floor_plans') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Floor Plans</span>
                                    </span>
                                </div>
                            </div>

                            <div class="row no-gutters mb-3">
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'drones')} type="primary" className={formData.services.indexOf('drones') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Drones</span>
                                    </span>
                                </div>
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'video')} type="primary" className={formData.services.indexOf('video') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Video</span>
                                    </span>
                                </div>
                            </div>

                            <div class="row no-gutters mb-3">
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'virtual_tours')} type="primary" className={formData.services.indexOf('virtual_tours') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Virtual Tours</span>
                                    </span>
                                </div>
                                <div class="col-6 pr-2">
                                    <span onClick={() => handleData('services', 'virtual_furniture')} type="primary" className={formData.services.indexOf('virtual_furniture') > -1 ? 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA selectedServices' : 'styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQA'}>
                                        <span className="alignb">Virtual Furniture</span>
                                    </span>
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => forceNext(3)} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ">Next</button>

                            </>
                        }

                        {step == 3 && !loader  && 
                       <span>
                            <h1 className="text-center m-0 main-booking-title">Where is the job?</h1>
                            <div class="row no-gutters mt-4 mb-3">
                                <div class="col-12 pr-2 pt-3">


                                    <GooglePlacesAutocomplete
                                        autocompletionRequest={{
                                            componentRestrictions: { country: ["au"] },
                                            fields: ["address_component", "geometry"],
                                            types: ["address"],
                                           language: 'en'
                                        }}
                                        minLengthAutocomplete={3}
                                        apiKey={'AIzaSyDFFq7k__ZJzvtJwRpgxN-k35e9_ZF8Uhs'}
                                        onLoadFailed={(error) =>
                                            console.error("Could not inject Google script", error)
                                        }
                                        className="autoadd"
                                        selectProps={{
                                            styles: {
                                                input: (provided) => ({
                                                  ...provided,
                                                  color: 'black',
                                                  paddingTop: '0.25rem',
                                                  paddingBottom: '0.25rem'
                                                }),
                                                option: (provided) => ({
                                                  ...provided,
                                                  color: '#000',
                                                }),
                                                singleValue: (provided) => ({
                                                  ...provided,
                                                  color: 'black',
                                                }),
                                              },
                                            components: {
                                            IndicatorSeparator: undefined,
                                            DropdownIndicator: undefined
                                            },
                                            placeholder: "Address",
                                            value: {
                                            // address shouldn't be undefined here. I can reproduce the error you describe if it is undefined
                                            value: address || {},
                                            label: addressLabel,
                                            },
                                            onChange: handleAddressChange,
                                            isClearable: false,
                                            pageSize: 3,
                                            TextFieldProps: {
                                            InputLabelProps: {
                                                shrink: addressLabel === "" ? undefined : true,
                                            },
                                            },
                                        }}
                                        style={{ maxHeight: '100px' }}
                                    />

                                    {formData.address && 
                                        <div class="row no-gutters mt-0 mb-0">
                                            <div class="col-12 pr-2 pt-3">
                                                <input type="text" class="form-control mt15" name="location_details" placeholder="Add any instructions here..." id="location_details" value={formData.location_details} onInput={e => handleContactDetails(e.target.value, 'location_details')} />
                                            </div>
                                        </div>
                                    }

                       

                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => forceNext(4)} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ">Next</button>
                            </span>
                        }

                        {step == 4 && !loader && 
                        <>
                            <h1 className="text-center m-0 main-booking-title">Tell us your preferred booking date and time?</h1>
                            <div class="row no-gutters mt-4 mb-3">
                                <div class="col-12 pr-2 pt-3">
                                <DateTimePicker format={'dd-MM-y h:mm a'} required={true} disableClock={true} onChange={handleDateChange} value={formData.value_date} wrapperClassName="datePicker" locale={'en-au'} />
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => forceNext(5)} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ">Next</button>
                        </>
                        }

                        {step == 5 && 
                        <>
                            <h1 className="text-center m-0 main-booking-title">How can we contact you?</h1>
                            <div class="row no-gutters mt-4 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="business_name" placeholder="Business name" id="name" value={formData.name} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'name')} />
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="full_name" placeholder="Full name" id="full_name" value={formData.full_name} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'full_name')} />
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-0">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="email" placeholder="Your email" id="email" value={formData.email} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'email')} />
                                </div>
                            </div>

                            <div class="row no-gutters mt-2 mb-3">
                                <div class="col-12 pr-2 pt-3">
                                    <input type="text" autoComplete="off" class="form-control mt15" name="phone_number" placeholder="Phone number" id="phone_number" value={formData.phone_number} disabled={loader} onInput={e => handleContactDetails(e.target.value, 'phone_number')} />
                                </div>
                            </div>

                            {errorMessage && <p className="errororder">{errorMessage}</p> }

                            <button onClick={() => submitForm()} class="styles__StyledButton-ethqdb-0-a styles__StyledAnchorButton-ethqdb-1 blKTQQ orderNow" disabled={loader}>Submit</button>
                        </>
                        }

                        {step == 6 && 
                        <>
                            <h1 className="text-center m-0 main-booking-title">Quote request submited</h1>
                            <p className="mt-4">We will contact you shortly to find out a little more about your job and provide you with a cost estimate.<br />
                            <br />In the meantime, here is a guide to our rates!<br /><br />Photography<br />Up to 10 photos from $185<br />Up to 20 photos from $320<br /><br />Floor Plans<br />Black & White from $165<br />Colour from $220<br /><br />Video<br />Up to 60 mins on-site from $385<br />Up to 120 mins on-site from $585<br /><br />Drone<br />Up to 30 mins on-site from $285<br />Up to 60 mins on-site from $350<br /><br />Can't wait?<br />Give us a call on 1300 160 338 : )</p>
                            </>
                        }

                        </div>
                    </div>
                </div>
            </div>
        </div>

   </div>
  )
}