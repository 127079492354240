import React from 'react';
import { ConfigProvider } from 'antd';

export default function AppProvider({ children }) {

  return (
    <ConfigProvider locale="fr">
      {children}
    </ConfigProvider>
  );
}