import React, { lazy, Suspense } from 'react';
import {
  Route,
  Redirect,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import ScrollToTop from './context/ScrollToTop';
import Home from './frontend/home';
import Payment from './frontend/payment';
import { PUBLIC_ROUTE } from './route.constants';

import './css/frontend/bootstrap.css';
import './css/frontend/frontend.css';

import { ParallaxProvider } from 'react-scroll-parallax';
import PageNotFound from './404Page';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.HOME,
    exact: true,
    component: Home,
  },
  {
    path: PUBLIC_ROUTE.PAYMENT,
    exact: true,
    component: Payment,
  }
];

export default function Routes() {
  return (
      <ParallaxProvider>
        <Router>
        <div>
          <Helmet>
            <title>Xorix</title>
          </Helmet>
          {/*
            A <Switch> looks through all its children <Route>
            elements and renders the first one whose path
            matches the current URL. Use a <Switch> any time
            you have multiple routes, but you want only one
            of them to render at a time
            :payment_id
          */}
          <ToastContainer />
          <ScrollToTop />
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
            <Route path="*" component={PageNotFound} />
          </Switch>
          </div>
        </Router>
      </ParallaxProvider>
  );
}
